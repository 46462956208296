import {
	Box,
	Flex,
	SimpleGrid,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCard } from '../../../common/components/StoryCard';
import { useRefreshers } from '../hooks/useRefreshers';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const KeepLearning = () => {
	const { t } = useTranslation();
	const stories = useRefreshers();
	const navigate = useNavigate();

	if (stories === undefined) {
		return <LoadingState />;
	}

	if (stories.length === 0) {
		return null;
	}

	return (
		<Box mt="4" p="3">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('keep_learning')}
				</Text>
			</Flex>
			<SimpleGrid spacing={5} columns={[1, 1, 2, 3]}>
				{stories.map((story) => (
					<StoryCard
						storyId={story.id}
						onCardClick={() => {
							navigate(
								`/call?storyId=${story.id}&contentAllocationId=${story.refresherId}&ref=home`
							);
						}}
						key={story.id}
						refresherId={story.refresherId}
						type={t('refresher')}
						person={story.persona.base.name}
						image={
							story.persona.base.avatar_file.sizes.thumbnail?.url ??
							story.persona.base?.avatar_file.url
						}
						title={story.title}
						role={`${story.persona.base.role} ${story.persona.base.workplace.name && `@${story.persona.base.workplace.name}`}`}
						tags={[]}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};
