import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { SchemaSessionResponseDto } from '@jam/api-sdk';
import { Info } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	title: string;
	hint: string;
	value: string;
};

const KpiStat: React.FC<Props> = ({ title, value, hint }: Props) => {
	return (
		<Box
			border={'1px'}
			borderColor={'#d9d9d9'}
			borderRadius={'24px'}
			w={'100%'}
			padding={6}
		>
			<Flex direction={'column'} gap="3">
				<Flex gap="1" alignItems={'center'}>
					<Text fontSize={'14px'} color="#0B1215">
						{title}
					</Text>
					<Tooltip
						p={3}
						lineHeight={'21px'}
						hasArrow
						maxW={'200px'}
						placement="top"
						bg={'#0B1215'}
						borderRadius={'24px'}
						fontSize={'12px'}
						border={'1px solid #E2E8F0'}
						label={hint}
					>
						<Info color="#D9D9D9" size={20} />
					</Tooltip>
				</Flex>
				<Flex alignItems={'flex-end'} justifyContent={'space-between'}>
					<Text fontSize={'20px'} color="#0B1215">
						{value}
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
};

export type KpiStatsProps = {
	kpis: SchemaSessionResponseDto['kpis'];
};

export const KpiStats: React.FC<KpiStatsProps> = ({ kpis }: KpiStatsProps) => {
	const { t } = useTranslation('call');
	return (
		<Flex gap="4" w={'100%'}>
			<KpiStat
				title={t('listen_ratio')}
				hint={t('listen_ratio_hint')}
				value={t('listen_ratio_value', {
					value: Math.round((kpis.listenRatio ?? 0) * 100),
				})}
			/>
			<KpiStat
				title={t('talk_speed')}
				hint={t('talk_speed_hint')}
				value={t('talk_speed_value', {
					value: ((kpis.talkSpeed ?? 0) * 60).toFixed(1),
				})}
			/>
			<KpiStat
				title={t('longest_monolog')}
				hint={t('longest_monolog_hint')}
				value={t('longest_monolog_value', {
					value: kpis.longestMonologue ?? 0,
				})}
			/>
		</Flex>
	);
};
