import {
	Box,
	Flex,
	Image,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSkipRefresherMutation } from '../../redux/api/learnerApi';
import { useGetStoryDetails } from '../hooks/useGetStoryDetails';
import { useStoryStatus } from '../hooks/useStoryStatus';
import { Chip } from './Chip';
import { XpIcon } from './Icons';
import { StoryLabels } from './StoryLabels';

type StoryCardProps = {
	showStatus?: boolean;
	storyId: string;
	refresherId?: string;
	type: string;
	title: string;
	person: string;
	role: string;
	image?: string;
	tags: string[];
	onCardClick?: () => void;
};

export const StoryCard = ({
	showStatus,
	storyId,
	type,
	title,
	person,
	role,
	image,
	tags,
	refresherId,
	onCardClick,
}: StoryCardProps) => {
	const { t } = useTranslation('common');
	const [skipRefresher] = useSkipRefresherMutation();
	const status = useStoryStatus(storyId);
	const story = useGetStoryDetails(storyId);
	const isRefresher = !!refresherId;

	const onSkip = async () => {
		if (refresherId) {
			await skipRefresher(refresherId);
		}
	};

	let statusText: string | null = null;
	if (status === 'IN_PROGRESS') {
		statusText = t('in_progress');
	} else if (status === 'NOT_STARTED') {
		statusText = t('not_started');
	} else if (status === 'COMPLETED') {
		statusText = t('completed');
	}

	return (
		<Flex
			direction={'column'}
			justifyContent={'space-between'}
			border={'1px'}
			pb={!showStatus ? '4' : '0'}
			borderColor={'gray.200'}
			borderRadius={'2xl'}
		>
			<Box mt="5" px="5">
				<Flex justifyContent={'space-between'} mb="2">
					<Chip text={type} />
					<Flex alignItems={'center'} gap={1}>
						{story && story.session_xp && story.story_xp && (
							<Chip
								bg="transparent"
								border={0}
								minH={'28px'}
								icon={<XpIcon />}
								text={`${story.session_xp} | ${story.story_xp}`}
							/>
						)}
						{isRefresher && (
							<Menu>
								<MenuButton>
									<DotsThreeVertical size={15} />
								</MenuButton>
								<MenuList>
									<MenuItem
										onClick={() => {
											void onSkip();
										}}
									>
										{t('skip')}
									</MenuItem>
								</MenuList>
							</Menu>
						)}
					</Flex>
				</Flex>
				<Box onClick={onCardClick} cursor={onCardClick ? 'pointer' : 'default'}>
					<Text minH="60px" fontSize={'20px'} fontWeight={'medium'}>
						{title}
					</Text>
					{image && (
						<Flex justifyContent={'center'} alignItems={'center'} py="4">
							<Image
								borderRadius={'12px'}
								maxW="140px"
								maxH="163px"
								src={image}
							/>
						</Flex>
					)}
					<Text fontSize={'16px'}>{person}</Text>
					<Text mt="2" fontSize={'12px'} color={'#757575'}>
						{role}
					</Text>
					<Flex gap="2" mt="2">
						{tags && tags.map((tag) => <Chip key={tag} text={tag} />)}
					</Flex>
					{story && <StoryLabels hideXp story={story} />}
				</Box>
			</Box>
			{showStatus && statusText && (
				<Box mt="4" borderBottomRadius={'2xl'} bg="#F7F5FF" p="2">
					<Text fontSize={'12px'} fontWeight={'medium'} color="#757575">
						{statusText}
					</Text>
				</Box>
			)}
		</Flex>
	);
};
