import {
	Avatar,
	Box,
	Flex,
	Input,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { SchemaSessionsTeamActivityReposeDto } from '@jam/api-sdk';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { t } from 'i18next';
import { useState } from 'react';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { useGetUserStoriesQuery } from '../../../../../redux/api/cmsApi';
import { useGetRolePlayActivityQuery } from '../../../../../redux/api/dashboardApi';
import { SolvedStatusToColorMap } from '../../../../user-history/components/HistoryTable';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

export const RoleplayAcrossUsersTable = () => {
	const [search, setSearch] = useState('');
	const customFilter = [{ id: 'userName', value: search }];
	const columnHelper =
		createColumnHelper<SchemaSessionsTeamActivityReposeDto>();
	const { data, isLoading } = useGetRolePlayActivityQuery();
	const { data: stories, isLoading: storiesLoading } = useGetUserStoriesQuery();

	if (isLoading || storiesLoading) return <LoadingState />;
	if (!data || !stories) return null;

	const columns = [
		columnHelper.accessor('userName', {
			header: t('common:user').toString(),
			cell: ({ row }) => (
				<Flex alignItems={'center'} gap="1">
					<Avatar
						bg="gray"
						size="sm"
						name={row.original.userName}
						src={row.original.userPictureUrl}
					/>
					<Text>{row.original.userName}</Text>
				</Flex>
			),
		}),
		// columnHelper.accessor('organizationName', {
		// 	header: t('common:team').toString(),
		// 	cell: ({ row }) => <Text>{row.original.organizationName}</Text>,
		// }),
		columnHelper.accessor('storyReferenceId', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				const story = stories.find(
					(x) => x.id === row.original.storyReferenceId
				);
				if (!story) return '-';

				return (
					<Text maxW={'200px'} isTruncated>
						{story.title}
					</Text>
				);
			},
		}),
		columnHelper.accessor('createdAt', {
			header: t('common:date-time').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Text>{formatDate(row.original.createdAt, 'MMMM d (HH:mm)')}</Text>
				);
			},
		}),

		columnHelper.accessor('type', {
			header: t('common:type').toString(),
			cell: ({ row }) => <Text>{t(`history:from-${row.original.type}`)}</Text>,
		}),
		columnHelper.accessor('results', {
			header: t('common:results').toString(),
			cell: ({ row }) => {
				const showMax = 3;
				const results = row.original.results;
				const areMany = results.length > showMax;
				const showResults = areMany ? results.slice(0, showMax - 1) : results;
				return (
					<Popover placement="top">
						<PopoverTrigger>
							<Flex alignItems={'center'} w="fit-content">
								{showResults.map((result, index) => {
									return (
										<Box
											ml="-2"
											w="22px"
											h="22px"
											bg={SolvedStatusToColorMap[result]}
											borderRadius={'full'}
											borderWidth={2}
											borderColor={'white'}
											key={index}
										/>
									);
								})}
								{areMany && (
									<Box
										ml="-2"
										bg={'black'}
										px="1"
										borderRadius={'full'}
										fontSize={'11px'}
										color={'white'}
										borderWidth={2}
										borderColor={'white'}
									>
										+{results.length - showMax + 1}
									</Box>
								)}
							</Flex>
						</PopoverTrigger>
						<PopoverContent px="4" py="2" w="fit-content">
							<Flex alignItems={'center'}>
								{results.map((result, index) => {
									return (
										<Box
											ml="-2"
											w="22px"
											h="22px"
											bg={SolvedStatusToColorMap[result]}
											borderRadius={'full'}
											borderWidth={2}
											borderColor={'white'}
											key={index}
										/>
									);
								})}
							</Flex>
						</PopoverContent>
					</Popover>
				);
			},
		}),
		// columnHelper.accessor('score', {
		// 	header: t('common:score').toString(),
		// 	cell: ({ row }) => (
		// 		<Text w="fit-content" bg={'#F5F5F5'} px="1" borderRadius={'full'}>
		// 			{row.original.score}
		// 		</Text>
		// 	),
		// }),
	];
	const finishedSessions = data.filter((x) => x.results.length > 0);
	return (
		<Flex gap={6} direction={'column'}>
			<Text
				lineHeight={'30px'}
				color="#0B1215"
				fontWeight={'medium'}
				fontSize="24px"
			>
				{t('dashboard:team_activity_section.roleplay_across_users')}
			</Text>
			<Stack mt="5" direction="column" spacing="5">
				<Flex gap="5">
					<Input
						maxW={'300'}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						borderRadius={'8px'}
						placeholder={t('common:search_by_name') ?? ''}
					/>
				</Flex>
				<CustomTable<SchemaSessionsTeamActivityReposeDto>
					data={finishedSessions}
					columns={columns}
					defaultSorting={[{ id: 'createdAt', desc: true }]}
					customFilters={customFilter}
					tableProps={{
						border: '1px solid #D9D9D9',
					}}
					headerProps={{
						bg: '#F5F5F5',
					}}
				/>
			</Stack>
		</Flex>
	);
};
