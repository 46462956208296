import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Flex,
	Text,
} from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import {
	ArrowSquareOut,
	FileText,
	Folder,
	Minus,
	Plus,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type MoreInfoTabProps = {
	story: SchemaStoryResponseDto;
};

export const MoreInfoTab = ({ story }: MoreInfoTabProps) => {
	const { t } = useTranslation('common');
	if (!story.additional_material || story.additional_material.length === 0) {
		return null;
	}
	const materials = story.additional_material.map((material) => {
		return {
			value: material.value as Record<string, any>,
			relationTo: material.relationTo,
		};
	});
	return (
		<Flex direction={'column'} gap="4">
			<Accordion
				allowMultiple
				defaultIndex={materials.map((_, index) => index)}
			>
				{materials.map((material, index) => {
					let icon = <FileText size={20} />;
					if (material.relationTo === 'client-documents') {
						icon = <Folder size={20} />;
					}
					if (material.relationTo === 'external-links') {
						icon = <ArrowSquareOut size={20} />;
					}
					return (
						<AccordionItem mb="5" key={index} border={0}>
							{({ isExpanded }) => (
								<Box
									borderRadius={'24px'}
									border={'1px'}
									borderColor={'#D9D9D9'}
									px="4"
									py="6"
								>
									<AccordionButton _hover={{ bg: 'transparent' }}>
										<Flex
											alignItems={'center'}
											w="100%"
											justifyContent={'space-between'}
										>
											<Flex gap="2" alignItems={'center'}>
												<Box
													border={'1px'}
													p="2"
													borderRadius={'24px'}
													borderColor={'#D9D9D9'}
												>
													{icon}
												</Box>
												<Text fontSize={'16px'} fontWeight={'medium'}>
													{material.value.title}
												</Text>
											</Flex>

											{material.relationTo === 'text-snippets' ? (
												<>
													{isExpanded ? (
														<Minus weight="bold" size={20} />
													) : (
														<Plus weight="bold" size={20} />
													)}
												</>
											) : (
												<Link
													to={
														material.relationTo == 'external-links'
															? (material.value.url as string)
															: (material.value.signedUrl as string)
													}
													onClick={(e) => {
														e.stopPropagation();
													}}
													target="_blank"
												>
													<Button
														fontWeight={'14px'}
														bg="transparent"
														_hover={{ bg: 'transparent' }}
														color={'#0B1215'}
														border={'1px'}
													>
														{material.relationTo === 'external-links'
															? t('open_link')
															: t('download_pdf')}
													</Button>
												</Link>
											)}
										</Flex>
									</AccordionButton>
									<AccordionPanel py="5">
										<Text fontSize={'14px'} color={'#757575'}>
											{material.value.description}
										</Text>
									</AccordionPanel>
								</Box>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>
	);
};
