import {
	Box,
	Button,
	Flex,
	Image,
	Progress,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { TrackLabels } from '../../../common/components/TrackLabels';
import { useCalculateTrackCompletion } from '../../../common/utils/calculateCompletion';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { StoryDetails } from './StoryDetails';

const LoadingState = () => {
	return (
		<Flex
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="5"
			w="100%"
			gap={'10'}
			direction={'column'}
		>
			<Box w="100%">
				<SkeletonText noOfLines={4} spacing="5" />
			</Box>
			<Flex w="100%" direction={'column'} gap="6">
				{Array(4)
					.fill(null)
					.map((_, index) => (
						<Flex key={index} gap="6" alignContent={'center'}>
							<Skeleton borderRadius={'8px'} h="90px" w="120px" size="10" />
							<SkeletonText w={'full'} noOfLines={4} spacing={'4'} />
						</Flex>
					))}
			</Flex>
		</Flex>
	);
};

export const CurrentTrackDetails = () => {
	const { t } = useTranslation('gym');
	const activeStory = useGetFirstNotCompletedStory();
	const completion: { completed: number; total: number; percentage: number } =
		useCalculateTrackCompletion(activeStory?.track);
	const navigate = useNavigate();
	if (activeStory === null) {
		return null;
	}

	if (activeStory === undefined) {
		return <LoadingState />;
	}

	const { track, story, contentAllocationId } = activeStory;
	return (
		<Box>
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('current_track')}
				</Text>
			</Flex>
			<Box p="4" border={'1px'} borderColor={'#d9d9d9'} borderRadius={'32px'}>
				<Flex justify={'space-between'}>
					<Text fontSize="24px" fontWeight="medium">
						{track.title}
					</Text>
					<Box>
						<Button
							id="resume-track-button"
							borderRadius={'32px'}
							textColor={'white'}
							bg={'#4241E4'}
							gap={'16px'}
							_hover={{ bg: '#4241E4' }}
							onClick={() => {
								navigate(
									`/call?storyId=${story.id}&contentAllocationId=${contentAllocationId ?? ''}&ref=gym&track=${track.title}`
								);
							}}
						>
							<Text>{t('resume')}</Text>
							<Image src="/icons/right-arrow.svg" />
						</Button>
					</Box>
				</Flex>
				<Flex>
					<TrackLabels track={track} />
				</Flex>
				<Flex mt="4" gap={'2'} alignItems={'center'}>
					<Chip
						display={'inline-block'}
						bg={'#D5C9FF'}
						text={`${completion.completed}/${completion.total}`}
					/>
					<Box w="100%">
						<Progress
							borderRadius={'25px'}
							size={'lg'}
							value={completion.completed}
							max={completion.total}
						/>
					</Box>
				</Flex>
				<Box p="4">
					<Text fontSize={'24px'} fontWeight={'semibold'}>
						{t('missions')}
					</Text>
					<Flex mt="4" direction={'column'} gap="10">
						{track.stories.map((s: SchemaStoryResponseDto, index: number) => {
							return (
								<StoryDetails
									key={s.id}
									number={index + 1}
									isLast={index === track.stories.length - 1}
									story={s}
									contentAllocationId={contentAllocationId}
									trackName={track.title}
								/>
							);
						})}
					</Flex>
				</Box>
			</Box>
		</Box>
	);
};
