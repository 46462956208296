import {
	Avatar,
	Box,
	Divider,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { SchemaSessionResponseDto, SchemaStoryResponseDto } from '@jam/api-sdk';
import { DotsThreeVertical, Info } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStartTime } from '../../../redux/slice';
import { formatSecondsToMinutesAndSeconds } from '../utils/formatSecondsToMinutesAndSeconds';

type TranscriptProps = {
	persona: SchemaStoryResponseDto['persona'];
	session: SchemaSessionResponseDto | null | undefined;
};

export const Transcript = ({ session, persona }: TranscriptProps) => {
	const { user } = useAuthInfo();
	const { t } = useTranslation('call');
	const startTime = useAppSelector(selectCurrentCallStartTime);
	const [seconds, setSeconds] = useState(0);
	let text = startTime ? t('call_is_being_transcribed') : t('transcript');

	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		if (startTime) {
			intervalRef.current = setInterval(() => {
				setSeconds((s) => s + 1);
			}, 1000);
		} else {
			setSeconds(0);
		}
		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [startTime]);

	const messages = session?.call?.messages.slice().sort((a, b) => {
		return a.secondsFromStart - b.secondsFromStart;
	});

	const renderTimer = () => {
		// time format to MM:SS
		const m = Math.floor(seconds / 60);
		const s = seconds % 60;
		const mm = m < 10 ? `0${m}` : m;
		const ss = s < 10 ? `0${s}` : s;

		return (
			<Box mt="2">
				<Divider />
				<Flex mt="2" alignItems={'center'} gap="1">
					<Box w="10px" h="10px" borderRadius={'24px'} bg="#FF6767"></Box>
					<Text fontSize={'12px'}>{`${mm}:${ss}`}</Text>
				</Flex>
			</Box>
		);
	};
	const hasMessages = (session?.call?.messages?.length ?? 0) > 0;

	if (!hasMessages) {
		text = t('transcript_not_available');
	}
	return (
		<Tooltip
			p={3}
			isDisabled={hasMessages}
			lineHeight={'21px'}
			hasArrow
			maxW={'200px'}
			placement="top"
			bg={'#0B1215'}
			borderRadius={'24px'}
			fontSize={'12px'}
			border={'1px solid #E2E8F0'}
			label={t('complete_one_attempt_transcript')}
		>
			<Box
				bg={hasMessages ? 'white' : '#FAFAFA'}
				cursor={hasMessages ? '' : 'pointer'}
				border={'1px'}
				p="5"
				h="fit-content"
				mt={4}
				borderRadius={'24px'}
				borderColor={'#D9D9D9'}
				width="500px"
			>
				<Flex mb="2" alignItems={'center'} justifyContent="space-between">
					<Flex alignItems={'center'} gap="2">
						{!hasMessages && (
							<Box
								cursor={'pointer'}
								border={'1px'}
								p="2"
								borderRadius={'24px'}
								borderColor={'#D9D9D9'}
							>
								<Info size={20} />
							</Box>
						)}
						<Text
							fontWeight={hasMessages ? 'medium' : 'normal'}
							fontSize={hasMessages ? '16px' : '14px'}
							color={hasMessages ? 'black' : '#757575'}
						>
							{text}
						</Text>
					</Flex>
					{hasMessages && (
						<Menu>
							<MenuButton>
								<Box p="2" borderRadius={'24px'} bg="#F5F5F5">
									<DotsThreeVertical size={15} />
								</Box>
							</MenuButton>
							<MenuList>
								<MenuItem>{t('restore')}</MenuItem>
							</MenuList>
						</Menu>
					)}
				</Flex>
				{startTime && renderTimer()}
				{!startTime && (
					<Box>
						{session?.call.recordingUrl && (
							<Box mt="2">
								<audio
									style={{ width: '100%' }}
									controls
									src={session.call.recordingUrl}
								/>
							</Box>
						)}

						{hasMessages && (
							<Stack
								maxH={'350px'}
								overflowY={'auto'}
								mt="3"
								direction="column"
								p="2"
							>
								{messages?.map((message, index) => {
									const isBot = message.role === 'prospect';
									const imageUrl = !isBot
										? user?.pictureUrl
										: persona.base.avatar_file.sizes.thumbnail?.url ??
											persona.base.avatar_file.url;
									const name = !isBot ? user?.firstName : persona.base.name;
									const rowOrder = !isBot ? 'row-reverse' : 'row';
									return (
										<Flex
											alignItems={isBot ? 'flex-start' : 'flex-end'}
											direction={rowOrder}
											gap="2"
											key={index}
											my={2}
										>
											<Avatar
												mt={isBot ? 4 : 0}
												size="sm"
												src={imageUrl ?? ''}
												name={name ?? ''}
											/>
											<Flex
												direction={'column'}
												alignItems={isBot ? 'flex-start' : 'flex-end'}
											>
												<Text fontSize={'12px'}>
													{formatSecondsToMinutesAndSeconds(
														message.secondsFromStart
													)}
												</Text>
												<Flex
													bgColor={'#F5F5F5'}
													px="4"
													py="2"
													w={'100%'}
													maxW={'220px'}
													borderTopRightRadius={'8px'}
													borderBottomRightRadius={isBot ? '8px' : '0'}
													borderTopLeftRadius={!isBot ? '8px' : '0'}
													borderBottomLeftRadius={'8px'}
													direction={'column'}
												>
													<Text color={'#757575'} fontSize={'12px'} my="1">
														{message.content}
													</Text>
												</Flex>
											</Flex>
										</Flex>
									);
								})}
							</Stack>
						)}
					</Box>
				)}
			</Box>
		</Tooltip>
	);
};
