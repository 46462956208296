import { Avatar, Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { Chip } from '../../../common/components/Chip';
import { useGetUserTotalExperienceQuery } from '../../../redux/api/learnerApi';
import { XpIcon } from '../../../common/components/Icons';
import { UserContextMenu } from '../../call/components/UserContextMenu';
import { UserWidgets } from '../../../common/components/UserWidgets';

type SalutationProps = {
	text: string;
};

const LoadingState = ({ isLoaded }: { isLoaded: boolean }) => {
	return (
		<Skeleton
			height={'32px'}
			w={'70px'}
			isLoaded={isLoaded}
			borderRadius={'16px'}
		/>
	);
};

export const Salutation = ({ text }: SalutationProps) => {
	return (
		<Box w="100%" mb="5" borderRadius={'md'} role="heading">
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex direction={'column'} gap={'24px'}>
					<Text fontSize="3xl" fontWeight="medium">
						{text}
					</Text>
				</Flex>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
