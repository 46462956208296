import {
	SchemaStoryResponseDto,
	SessionResponseDTOAnalysisItemsSolved,
} from '@jam/api-sdk';
import { useGetJourneyDetailsQuery } from '../../../redux/api/cmsApi';
import {
	useGetUserJourneyStateQuery,
	useGetUserSessionsQuery,
} from '../../../redux/api/dashboardApi';
import { useGetUserJourneyContentAllocationQuery } from '../../../redux/api/learnerApi';

export type MissionWithSession = {
	title: string;
	situation: SchemaStoryResponseDto['situation'];
	persona: SchemaStoryResponseDto['persona'];
	sessionsCount: number;
	tasks: {
		title: string;
		items: SessionResponseDTOAnalysisItemsSolved[];
	}[];
};

export type UserTrackWithSessions = {
	title: string;
	missionCount: number;
	completedMissionCount: number;
	missions: MissionWithSession[];
};

export const useGetUserJourneyWithSessions = (
	userId: string | undefined
): UserTrackWithSessions[] | undefined => {
	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const { data: journeyDetails } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId ?? '',
		{
			skip: !userJourney,
		}
	);

	const { data: journeyState } = useGetUserJourneyStateQuery(
		{
			userId: userId ?? '',
			journeyId: userJourney?.journeyReferenceId ?? '',
		},
		{
			skip: !userJourney || !userId,
		}
	);

	const { data: userSessions } = useGetUserSessionsQuery(userId ?? '', {
		skip: !userId,
	});

	if (!journeyDetails || !userJourney || !journeyState || !userSessions) {
		return undefined;
	}

	const data: UserTrackWithSessions[] = [];
	journeyDetails.tracks.forEach((track) => {
		const trackStatus = journeyState.trackStatuses.find(
			(status) => status.trackId === track.id
		);
		if (!trackStatus) {
			return;
		}
		const info = {
			title: track.title,
			missionCount: track.stories.length,
			completedMissionCount: trackStatus.completedStoryIds.length,
			missions: track.stories.map((story) => {
				const storyItems = userSessions
					.filter((x) => x.storyReferenceId === story.id)
					.flatMap((x) => x.analysis.items);
				const sessionsCount =
					userSessions.filter(
						(x) => x.storyReferenceId === story.id && x.state === 'DONE'
					).length ?? 0;
				return {
					title: story.title,
					situation: story.situation,
					persona: story.persona,
					sessionsCount,
					tasks: story.items_conversation_feedback
						.filter((x) => !!x.scorecard_title)
						.map((task) => {
							return {
								title: task.scorecard_title ?? '',
								items: storyItems
									.filter((x) => x.conversationItemReferenceId === task.id)
									.map(
										(x) =>
											x.solved ??
											SessionResponseDTOAnalysisItemsSolved.undetected
									),
							};
						}),
				};
			}),
		};

		data.push(info);
	});

	return data;
};
