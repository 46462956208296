import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';
import { useUserOrg } from '../../../common/hooks/useUserOrg';
import { useInviteUserToOrganizationMutation } from '../../../redux/api/accountApi';

type AddUserModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

type UserData = {
	email: string;
	role: string;
};

const UserDataSchema: ZodType<UserData> = z.object({
	email: z.string().email('Invalid email format').min(1),
	role: z.string().min(1),
});

export const AddUserModal = ({ isOpen, onClose }: AddUserModalProps) => {
	const toast = useToast();
	const { t } = useTranslation('settings');
	const [inviteUserToOrg] = useInviteUserToOrganizationMutation();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<UserData>({
		resolver: zodResolver(UserDataSchema),
	});
	const org = useUserOrg();

	if (!org) return null;

	const onSubmit = async (data: UserData) => {
		await inviteUserToOrg({
			orgId: org.orgId,
			email: data.email,
			role: data.role,
		});
		onClose();
		reset();
		toast({
			title: t('user-invited-successfully'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	return (
		<Modal
			size={'lg'}
			isOpen={isOpen}
			onClose={() => {
				reset();
				onClose();
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'2xl'}>{t('add-user-title')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={4}>
						<FormControl isInvalid={!!errors.email}>
							<FormLabel>{t('email-address')}</FormLabel>
							<Input borderRadius={'full'} {...register('email')} />
							{errors.email?.message && (
								<FormErrorMessage>{errors.email.message}</FormErrorMessage>
							)}
						</FormControl>
						<FormControl>
							<FormLabel>{t('role')}</FormLabel>
							<Select borderRadius={'full'} {...register('role')}>
								<option value="Owner">Owner</option>
								<option value="Manager">Manager</option>
								<option value="Member">Member</option>
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="add-user-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={isDirty ? 'brand.500' : 'gray.500'}
						size="md"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
						mr={3}
					>
						{t('invite')}
					</Button>
					<Button
						borderRadius={'32px'}
						textColor={'black'}
						bg={'white'}
						gap={'16px'}
						border={'1px'}
						_hover={{ bg: 'white' }}
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
