import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useGetUserStoriesQuery } from '../../../redux/api/cmsApi';
import { useGetRefreshersQuery } from '../../../redux/api/learnerApi';

type StoryRefresher = SchemaStoryResponseDto & { refresherId: string };

export const useRefreshers = (): StoryRefresher[] | undefined => {
	const { data: refreshers, isLoading: isLoadingRefreshers } =
		useGetRefreshersQuery();
	const { data: stories, isLoading: isLoadingStories } =
		useGetUserStoriesQuery();

	if (isLoadingRefreshers || isLoadingStories) {
		return undefined;
	}

	const refreshersStories = refreshers
		?.map((refresher) => {
			const story = stories?.find(
				(ref) => ref.id === refresher.storyReferenceId
			);
			if (!story) {
				return null;
			}

			return {
				...story,
				refresherId: refresher.id,
			};
		})
		.filter((r) => !!r) as StoryRefresher[];

	return refreshersStories || [];
};
