import { Flex } from '@chakra-ui/react';
import { CompletionAcrossUsers } from './CompletionAcrossUsers';
import { WeeklyActivity } from './WeeklyActivity';
import { RoleplayAcrossUsersTable } from './RoleplayAcrossUsersTable';

export const TeamActivity = () => {
	return (
		<Flex direction={'column'} gap={6} py="6">
			<CompletionAcrossUsers />
			<WeeklyActivity />
			<RoleplayAcrossUsersTable />
		</Flex>
	);
};
