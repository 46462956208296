import { Box, Flex } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../redux/slice';
import { Sidebar } from './SideBar';

type AppLayoutProps = {
	children: React.ReactNode;
	hideSidebar?: boolean;
	fullWidth?: boolean;
};

export const AppLayout = ({
	children,
	hideSidebar,
	fullWidth,
}: AppLayoutProps) => {
	const { user } = useAuthInfo();
	const { i18n } = useTranslation();
	const language = useSelector(selectLanguage);

	useEffect(() => {
		if (user) {
			Sentry.setUser({
				email: user.email,
			});
		}
	}, [user]);

	if (i18n.language !== language) return null;

	return (
		<Flex as="section" h="100vh" bg={'white'}>
			{!hideSidebar && <Sidebar />}
			<Flex
				flexShrink={1}
				direction={'column'}
				gap="24px"
				w="100%"
				bg={'white'}
				overflowY={'auto'}
				py={{ base: '10px', md: '30px', xl: '80px' }}
				px={{ base: '10px', md: '30px', xl: '80px' }}
			>
				<Box w="100%" mx={'auto'} maxW={fullWidth ? 'full' : '1300px'}>
					{children}
				</Box>
			</Flex>
		</Flex>
	);
};
