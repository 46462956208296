import {
	SessionResponseDTOAnalysisItemsSolved,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk';
import {
	useGetStoryLastSessionQuery,
	useGetUserStoryFeedbackQuery,
} from '../../../redux/api/learnerApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectHasUserMadeCall } from '../../../redux/slice';
import { useActiveStory } from '../../home/hooks/useActiveStory';

export const useShouldAskForFeedback = () => {
	const hasUserMadeCall = useAppSelector(selectHasUserMadeCall);
	const { activeStory } = useActiveStory();
	const { data: userFeedback, isLoading: userFeedbackLoading } =
		useGetUserStoryFeedbackQuery(activeStory ?? '', {
			skip: !activeStory,
		});

	const { data: lastSession } = useGetStoryLastSessionQuery(
		activeStory as string,
		{
			skip: !activeStory || !hasUserMadeCall,
		}
	);

	// if the user has not made a call, we should not ask for feedback yet
	if (!hasUserMadeCall || !lastSession || !activeStory) return false;

	// only ask for feedback if the last session is completed
	const isCompleted =
		lastSession.analysis &&
		lastSession.analysis.state === SessionResponseDTOAnalysisState.DONE
			? lastSession.analysis?.items.every(
					(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
				)
			: false;

	if (!isCompleted) return false;

	// only ask for feedback if the user has not already given feedback
	if (userFeedbackLoading || (userFeedback && userFeedback.length > 0))
		return false;

	return true;
};
