import { Skeleton, Stack } from '@chakra-ui/react';
import {
	SessionResponseDTOAnalysisItemsSolved,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk';
import { useEffect, useState } from 'react';
import { AppLayout } from '../common/components/AppLayout';
import { useActiveContentAllocationId } from '../common/hooks/useActiveContentAllocationId';
import { CallDetails } from '../features/call/components/CallDetails';
import { CallHeader } from '../features/call/components/CallHeader';
import { Caller } from '../features/call/components/Caller';
import { useActiveSession } from '../features/call/hooks/useActiveSession';
import { useActiveStory } from '../features/home/hooks/useActiveStory';
import { useGetStoryDetailsQuery } from '../redux/api/cmsApi';
import {
	useGetSessionDetailsQuery,
	useGetStoryLastSessionQuery,
	useGetStorySessionsQuery,
	useLazyGetUserTotalExperienceQuery,
} from '../redux/api/learnerApi';

const LoadingState = () => {
	return (
		<AppLayout hideSidebar fullWidth>
			<CallHeader />
			<Stack direction="column" h="100%" spacing={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</AppLayout>
	);
};

export const CallPage = () => {
	const { activeStory } = useActiveStory();
	const { activeContentAllocationId } = useActiveContentAllocationId();
	const { activeSession } = useActiveSession();
	const [polling, setPolling] = useState(0);
	const [getUserTotalExperience] = useLazyGetUserTotalExperienceQuery();
	const { data: story, isLoading } = useGetStoryDetailsQuery(
		activeStory || '',
		{
			skip: !activeStory,
		}
	);

	const { data: storySessions } = useGetStorySessionsQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: lastSession } = useGetStoryLastSessionQuery(activeStory || '', {
		// skip the query if there is no active story or if there is an active session
		skip: !activeStory || !!activeSession,
		pollingInterval: polling,
	});
	const { data: currentSession } = useGetSessionDetailsQuery(
		activeSession || '',
		{
			skip: !activeSession,
		}
	);

	const session = activeSession ? currentSession : lastSession;

	// This useEffect is used to set the polling interval for the last session query based on the status of the last session analysis object.
	useEffect(() => {
		if (!session) {
			return;
		}
		if (
			session.analysis === null ||
			session.analysis.state !== SessionResponseDTOAnalysisState.DONE
		) {
			setPolling(3000);
		} else {
			setPolling(0);
			void getUserTotalExperience();
		}
	}, [setPolling, session]);

	if (isLoading) {
		return <LoadingState />;
	}

	if (!story) {
		return null;
	}

	const analysis = session?.analysis;
	const completedItems =
		analysis?.items.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		).length || 0;
	const allItems =
		analysis?.items.length || story.conversation_topic.length || 0;
	return (
		<AppLayout hideSidebar fullWidth>
			<CallHeader />
			<Stack direction="column" h="100%" spacing={10} p={10}>
				<Caller
					numberOfRounds={storySessions?.length || 0}
					contentAllocationId={activeContentAllocationId}
					story={story}
					conversationType={story.situation.conversation_type}
					completedItems={completedItems}
					allItems={allItems}
				/>
				<CallDetails
					completedItems={completedItems}
					allItems={allItems}
					story={story}
					session={session}
				/>
			</Stack>
		</AppLayout>
	);
};
