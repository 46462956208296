import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { SchemaSessionResponseDto, SchemaStoryResponseDto } from '@jam/api-sdk';
import { Lock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { HintsAndGoals } from './HintsAndGoals';
import { SessionFeedback } from './SessionFeedback';
import { Transcript } from './Transcript';
import { MoreInfoTab } from './more-info/MoreInfoTab';

type CallDetailsProps = {
	completedItems: number;
	allItems: number;
	story: SchemaStoryResponseDto;
	session: SchemaSessionResponseDto | undefined | null;
};

export const CallDetails = ({
	story,
	session,
	completedItems,
	allItems,
}: CallDetailsProps) => {
	const { t } = useTranslation('call');
	const { activeCallTabIndex, setActiveCallTabIndex } = useActiveCallTabIndex();

	if (!story.situation.goal) {
		return null;
	}
	return (
		<Flex gap="10">
			<Tabs
				w={'100%'}
				isLazy
				index={activeCallTabIndex ?? 0}
				variant={'unstyled'}
			>
				<TabList p="2" w={'fit-content'} minW={'300px'}>
					<Tab
						fontWeight={'medium'}
						borderRadius={'24px'}
						_selected={{ color: '#4241E4' }}
						onClick={() => setActiveCallTabIndex(null)}
						fontSize={'16px'}
					>
						{t('mission')}
					</Tab>
					<Tab
						fontSize={'16px'}
						fontWeight={'medium'}
						borderRadius={'24px'}
						_selected={{ color: '#4241E4' }}
						onClick={() => setActiveCallTabIndex(1)}
						isDisabled={(story.additional_material?.length ?? 0) === 0}
					>
						{(story.additional_material?.length ?? 0) === 0 ? (
							<Tooltip
								p={3}
								lineHeight={'21px'}
								hasArrow
								maxW={'200px'}
								placement="top"
								bg={'#0B1215'}
								borderRadius={'24px'}
								fontSize={'12px'}
								border={'1px solid #E2E8F0'}
								label={t('no_more_info_hint')}
							>
								<Text color={'#757575'}>{t('more_info')}</Text>
							</Tooltip>
						) : (
							<Text>{t('more_info')}</Text>
						)}
					</Tab>
					<Tab
						fontSize={'16px'}
						fontWeight={'medium'}
						borderRadius={'24px'}
						_selected={{ color: '#4241E4' }}
						onClick={() => setActiveCallTabIndex(2)}
						isDisabled={!session}
					>
						{!session ? (
							<Tooltip
								bg={'#0B1215'}
								lineHeight={'21px'}
								hasArrow
								maxW={'200px'}
								placement="top"
								p={3}
								borderRadius={'24px'}
								fontSize={'12px'}
								border={'1px solid #E2E8F0'}
								label={t('complete_one_attempt_feedback')}
							>
								<Flex alignItems={'center'} gap="1">
									<Text color={'#757575'}>{t('feedback')}</Text>
									<Lock color={'#757575'} size={16} />
								</Flex>
							</Tooltip>
						) : (
							<Text>{t('feedback')}</Text>
						)}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<Flex w="100%">
					<TabPanels w="100%">
						<TabPanel pl={0} w="100%">
							<HintsAndGoals story={story} />
						</TabPanel>
						<TabPanel pl={0} w="100%">
							<MoreInfoTab story={story} />
						</TabPanel>
						<TabPanel pl={0} w="100%">
							<SessionFeedback
								completedItems={completedItems}
								allItems={allItems}
								session={session}
								story={story}
							/>
						</TabPanel>
					</TabPanels>
					<Transcript session={session} persona={story.persona} />
				</Flex>
			</Tabs>
		</Flex>
	);
};
