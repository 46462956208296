import { useAuthInfo } from '@propelauth/react';
import { UseAuthInfoProps } from '@propelauth/react/dist/types/hooks/useAuthInfo';
import { useUserOrg } from './useUserOrg';

/**
 * checks if the user is an owner in any organization
 * @returns true if user is owner of any organization
 */
export const useIsOwner = (): boolean => {
	const authInfo: UseAuthInfoProps = useAuthInfo();
	const org = useUserOrg();

	if (!org) return false;

	return !!authInfo.accessHelper?.isRole(org.orgId, 'Owner');
};
